/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Esta es la forma más sencilla de ver el log de pulseaudio en Debian. Empezamos por crear el archivo ~/.config/systemd/user/pulseaudio.service"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">[Service] ExecStart=/usr/bin/pulseaudio --daemonize=no -vvvv --log-time=1</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Reiniciamos el servicio:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">systemctl --user daemon-reload</code>"
    }
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">systemctl --user restart pulseaudio.service</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Ahora ya podremos ver el log con el comando journalctl:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">journalctl --user --unit=pulseaudio.service --follow</code>"
    }
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
